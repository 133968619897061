import React from "react"

export const ImageFilters = () => {
  return (
    <svg
      id="image-filters"
      className="absolute size-0 overflow-hidden"
      aria-hidden="true"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        {/* Blue filter */}
        <filter
          id="blue-filter"
          x="-10%"
          y="-10%"
          width="120%"
          height="120%"
          filterUnits="objectBoundingBox"
          primitiveUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feColorMatrix
            type="matrix"
            values=".33 .33 .33 0 0
            .33 .33 .33 0 0
            .33 .33 .33 0 0
            0 0 0 1 0"
            in="SourceGraphic"
            result="colormatrix"
          />
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="0.09 1" />
            <feFuncG type="table" tableValues="0.07 1" />
            <feFuncB type="table" tableValues="0.76 1" />
            <feFuncA type="table" tableValues="0 1" />
          </feComponentTransfer>
          <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend" />
        </filter>
        {/* Image masks */}
        <clipPath id="store-slider-card-thumbnail-mask">
          <path d="M170.472 152.216c-42.929 8.056-85.8563 16.125-128.785 24.18-7.648 1.447-14.3664-4.129-15.0083-12.479L16.4927 31.4036c-.6419-8.3503 5.1138-15.2996 12.854-15.5462C72.783 14.4059 116.22 12.968 159.657 11.5165c7.74-.2466 14.456 5.3022 15 12.37l8.677 112.8925c.544 7.068-5.214 13.99-12.862 15.437Z" />
        </clipPath>
        <clipPath id="store-card-tertiary-thumbnail-mask">
          <path d="M308.242 292.874c-83.465 9.282-166.93 18.591-250.3948 27.873-14.8715 1.677-26.9525-9.979-26.9845-26.081l-.5078-255.5345c-.032-16.1024 12.001-28.6003 26.8771-27.962 83.49 3.4522 166.981 6.9307 250.471 10.3829 14.876.6383 26.957 12.2415 26.984 25.8707l.433 217.6989c.027 13.629-12.006 26.075-26.878 27.752Z" />
        </clipPath>
        <clipPath id="rounded-square-hero-image-mask">
          <path
            d="M499.206 373.578C367.257 400.32 235.31 427.096 103.361 453.839C79.8534 458.633 59.0315 446.054 56.8462 425.682L22.1664 102.402C19.9811 82.0304 37.5249 64.0527 61.3464 62.1877C195.024 51.5558 328.706 40.9572 462.384 30.3252C486.206 28.4602 507.02 40.9732 508.87 58.2157L538.415 333.63C540.265 350.872 522.714 368.783 499.206 373.578Z"
            fill="#E7D8FF"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
