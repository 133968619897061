import { isABotUserAgent } from "./user-agent"

interface IpValidationResponse {
  isBot: boolean
}

export async function isBot(): Promise<boolean> {
  try {
    const response = await fetch("https://marketing.bounce.com/api/is-bot")
    const { isBot = false } = (await response.json()) as IpValidationResponse
    return isBot || isABotUserAgent()
  } catch {
    return false
  }
}
