import type { JSONObject } from "@segment/analytics-next"
import { AnalyticsBrowser } from "@segment/analytics-next"

import { isBot } from "@bounce/util"

import { AppStateEvents } from "../../events"
import { error } from "../../helpers/error"
import { clearEventsQueue, hasEventInQueue, pushEventToQueue } from "../../helpers/events"
import { amplitudeSessionPlugin } from "../../integrations/AmplitudeSessionPlugin/AmplitudeSessionPlugin"
import { LoggerIntegration } from "../../integrations/logger/logger"
import { UserAgentIntegration } from "../../integrations/userAgent/userAgent"
import type { SegmentClient } from "../../types"
import { AnalyticsEventTypes } from "../../types"

let isSettingUp = false

const Segment: SegmentClient<AnalyticsBrowser> = {
  client: undefined,
  isSetup() {
    return !!this.client
  },
  async setup({ writeKey, cdnURL, proxy, debug, logger, anonymousId, integrationsToLoad, webIntegrations = [] }) {
    if (!writeKey || (await isBot()) || this.isSetup() || isSettingUp) return

    isSettingUp = true

    const integrations: Record<string, boolean | JSONObject | undefined> = {
      All: !integrationsToLoad,
      "Segment.io": true,
      // Deactivating this explicitly to avoid conflicts with our custom AmplitudeSessionPlugin
      "Actions Amplitude": false,
      ...integrationsToLoad,
    }
    const plugins = [UserAgentIntegration, amplitudeSessionPlugin(), ...webIntegrations]

    // From the docs: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/custom-proxy/#custom-cdn--api-proxy
    if (proxy) {
      const proxyURL = new URL(proxy)
      integrations["Segment.io"] = {
        apiHost: proxyURL.host + proxyURL.pathname,
        protocol: "https",
      }
    }

    const client = AnalyticsBrowser.load({ writeKey, cdnURL }, { integrations })

    // Set anonymous ID if it's provided
    if (anonymousId) {
      const user = await client.user()
      user.anonymousId(anonymousId)
    }

    client.debug(debug)

    // need to set client the window.analytics for segment's consent manager
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (window && !window.analytics) {
      // @ts-ignore
      window.analytics = client
    }

    // Load custom integrations: Logger, Intercom, UserAgent...
    if (logger && process.env.NODE_ENV !== "test") {
      plugins.push(LoggerIntegration)
    }
    await client.register(...plugins)

    // Send an event when the client is loaded
    // To trigger the segment actions on load eg: GA4 web setConfigurationFields
    await client.track(AppStateEvents.LoadedSegment)

    this.client = client

    await clearEventsQueue(this)

    isSettingUp = false
  },
  page(category, name, properties) {
    // Replace existing page event in queue with the incoming one
    if (hasEventInQueue(this, AnalyticsEventTypes.PAGE)) {
      this.queuedEvents = this.queuedEvents?.filter(e => e.type !== AnalyticsEventTypes.PAGE)
      pushEventToQueue(this, { name, category, properties, type: AnalyticsEventTypes.PAGE })
      return
    }

    if (!this.client) {
      pushEventToQueue(this, { name, category, properties, type: AnalyticsEventTypes.PAGE })
      return
    }

    void this.client.page(category, name, properties)
  },
  screen(_name) {
    throw error("screen method is only supported on native")
  },
  track(eventName, properties) {
    if (!this.client) {
      pushEventToQueue(this, { name: eventName, properties, type: AnalyticsEventTypes.TRACK })
      return
    }

    void this.client.track(eventName, properties)
  },
  async identify(userId, traits) {
    if (!this.client) {
      pushEventToQueue(this, { properties: { userId, traits }, type: AnalyticsEventTypes.IDENTIFY }, true)
      return
    }

    await this.client.identify(userId, traits)
    await clearEventsQueue(this)
  },
  async reset() {
    if (!this.client) return

    return this.client.reset()
  },
  async getAnonymousId() {
    const clientReady = await isClientAvailable(this.client)
    if (!this.client || !clientReady) return ""

    const user = await this.client.user()
    return user.anonymousId() || ""
  },

  async setAnonymousId(id: string) {
    const clientReady = await isClientAvailable(this.client)
    if (!this.client || !clientReady) return

    const user = await this.client.user()
    user.anonymousId(id)
  },
}

const isClientAvailable = async (client: AnalyticsBrowser | undefined): Promise<boolean> => {
  if (!client) return false
  try {
    await client
    return true
  } catch (e) {
    return false
  }
}

export { Segment }
