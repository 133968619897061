import { createContext, useContext } from "react"

type DesignSystemTheme = "app" | "marketing"

const ThemeContext = createContext<DesignSystemTheme>("app")

type ThemeProviderProps = {
  children: React.ReactNode
  theme: DesignSystemTheme
}

export const ThemeProvider = ({ children, theme }: ThemeProviderProps) => (
  <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
)

export const useTheme = () => useContext(ThemeContext)

export const useIsAppTheme = () => useTheme() === "app"
